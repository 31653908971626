<template>
  <div v-if="isLongText">
    <div v-if="!isExpanded" class="leading-[0px]">
      <div :class="slotClasses" class="inline">
        {{ truncatedText }}
      </div>

      <span
        :class="slotClasses"
        class="cursor-pointer !whitespace-nowrap text-theme-3 hover:underline"
        @click="expand"
        >{{ $t("common.read-more") }}</span
      >
    </div>

    <div v-else>
      <slot></slot>

      <span
        :class="slotClasses"
        class="cursor-pointer !whitespace-nowrap text-theme-3 hover:underline"
        @click="collapse"
        >{{ $t("common.read-less") }}</span
      >
    </div>
  </div>

  <div v-else>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, useSlots } from "vue";

const maxLength = 175;
const isExpanded = ref(false);
const slots = useSlots();

const props = withDefaults(
  defineProps<{
    forceReadMore: boolean;
  }>(),
  {
    forceReadMore: false,
  }
);

const text = computed(() => {
  return slots?.default && slots.default()[0]?.children;
});

const isLongText = computed(() => {
  if (props.forceReadMore) return true;
  const slotContent = text?.value;
  if (typeof slotContent !== "string") return false;
  return slotContent.length > maxLength;
});

const truncatedText = computed(() => {
  const slotContent = text?.value;
  if (typeof slotContent !== "string") return slotContent;
  return slotContent.slice(0, maxLength) + "... ";
});

const slotClasses = computed(() => {
  const node = slots?.default && slots.default()[0];
  return node?.props?.class || "";
});

const expand = () => {
  isExpanded.value = true;
};

const collapse = () => {
  isExpanded.value = false;
};
</script>

<style scss scoped></style>
