<template>
  <div class="flex flex-row items-center">
    <a v-if="props.links?.personal" class="mr-2" :href="props.links.personal" target="_blank">
      <img :src="personalIcon.src" alt="website" />
    </a>

    <a v-if="props.links?.facebook" class="mr-2" :href="props.links.facebook" target="_blank">
      <img :src="facebookIcon.src" alt="facebook" />
    </a>

    <a v-if="props.links?.instagram" class="mr-2" :href="props.links.instagram" target="_blank">
      <img :src="instagramIcon.src" alt="instagram" />
    </a>

    <a v-if="props.links?.youtube" class="mr-2" :href="props.links.youtube" target="_blank">
      <img :src="youtubeIcon.src" alt="youtube" />
    </a>

    <a v-if="props.links?.tiktok" :href="props.links.tiktok" target="_blank">
      <img :src="tiktokIcon.src" alt="tiktok" />
    </a>
  </div>
</template>

<script setup lang="ts">
import type { SocialLinks } from "@/features/sell-a-profile/services/api";
import facebookIcon from "@/features/sell-a-profile/assets/social-icons/facebook.svg";
import instagramIcon from "@/features/sell-a-profile/assets/social-icons/instagram.svg";
import personalIcon from "@/features/sell-a-profile/assets/social-icons/personal.svg";
import tiktokIcon from "@/features/sell-a-profile/assets/social-icons/tiktok.svg";
import youtubeIcon from "@/features/sell-a-profile/assets/social-icons/youtube.svg";

const props = defineProps<{
  links: SocialLinks;
}>();
</script>

<style lang="scss" scoped></style>
